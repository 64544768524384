import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import { Category } from "../components/category"
import { Menu } from "../components/menu"
import Calendar from 'react-calendar';
import { HourBtn } from "../components/hour-btn";
import { MinuteBtn } from "../components/minute-btn";

import { apiGetCategories, apiGetMenus, apiGetOrgans, apiGetStaffs, apiGetEnableOrderHours, companyId, apiReserveOrder } from '../config/const';
import { loadData } from '../https/load-data';
import { dateFormat, dateFormatDay } from '../utils/date-format';
import { useNavigate } from 'react-router-dom';
import { StaffTypeItem } from '../components/staff-type-item';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { reserveOrder } from '../https/reserve-order';
import { LoadMask } from '../components/load-mask';

import Modal from 'react-bootstrap/Modal';
function Order(){

    const {user_id}=useSelector(state=>state.auth)
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(false);

    const [organId, setOrganId] = useState(0);
    const [staffType, setStaffType] = useState(0);
    const [staffId, setStaffId] = useState(0);
    const [selectedMenus, setSeleteMenu] = useState([]);
    
    const [seletedDate, setSelecteDate] = useState();

    const [categoryId, setCategoryId] = useState(0);
    const [categories, setCategoryPosts] = useState([]);
    const [organs, setOrganPosts] = useState([]);
    const [staffs, setStaffPosts] = useState([]);
    const [menus, setMenuPosts] = useState([]);
    const [reserves, setReservePosts] = useState({'dates':[], 'hours':[], 'mins' : []});

    const [selectHour, setSelectHour] = useState('');
    const [selectMin, setSelectMin] = useState('');
    const [isEnableReserve, setEnableReserve] = useState(false);
    const [completeOrder, setCompleteOrder] = useState([]);

    
    useEffect(() => {
        if (user_id === 0) navigate('/');

        let organParam=new FormData();
        organParam.append('company_id', companyId);
        loadData(apiGetOrgans, organParam, setOrganPosts);
        
    }, [user_id]);


    useEffect(() => {
        if (selectMin){
            setEnableReserve(true);    
        }else{
            setEnableReserve(false);
        }
    }, [selectMin]);

    useEffect(() => {
        if (!completeOrder.order) return;
        console.log(completeOrder);
        navigate('/complete', { state : {'order': completeOrder.order, 'orderMenus' : completeOrder.order_menus} });
    }, [completeOrder]);

    const handlerOrganId = (e) => {
        setShowSpinner(true);
        var selId = e.target.value;
        if (selId){
            let categoryParam=new FormData();
            categoryParam.append('is_add_all', 1);
            loadData(apiGetCategories, categoryParam, setCategoryPosts);

            let param=new FormData();
            param.append('organ_id', selId);
            loadData(apiGetStaffs, param, setStaffPosts);

            param.append('is_user_menu', 1);
            loadData(apiGetMenus, param, setMenuPosts);

            // let daysParam = new FormData();
            // daysParam.append('organ_id', selId);
            // daysParam.append('menu_ids', selectedMenus);
            // loadData(apiGetEnableOrderDays, daysParam, handlerEnableDates);
        } else {
            setCategoryPosts([]);
            setStaffPosts([]);
            setMenuPosts([]);
            // setEnableDayPosts({'maxDate': new Date(), 'enableDates' : []});
            // setHourTime([]);
        }
        
        setSeleteMenu([]);
        setStaffId(0);
        setSeleteMenu([]);
        setCategoryId(0);

        setOrganId(selId);
        setShowSpinner(false);
    }

    const handlerSelectMenu = (menuId) => {
        
        if (selectedMenus.includes(menuId)){
            setSeleteMenu(current => selectedMenus.filter((e) => e != menuId));

        }else{
            setSeleteMenu(current => [...selectedMenus, menuId]);
        }
    }

    const handlerSelectCategory = (id) => {
        setCategoryId(id);
        setSeleteMenu([]);
    }

    const handlerStaffId = (e) => {
        var selId = e.target.value;

        setStaffId(selId);
    }

    const handlerStaffType = (value) => {
        setStaffType(value);
    }

    const handlerSelectDate = (date) => {
        setSelecteDate(dateFormat(date));
    }

    useEffect(()=>{
        setSelecteDate('');
        setSelectHour('');
        setSelectMin('');

        if (!organId || (staffType==3 && !staffId) || selectedMenus.length==0){
            setSelecteDate('');
            setSelectHour('');
            setReservePosts({'dates':[], 'hours':[], 'mins' : []});
            return;
        }

        setShowSpinner(true);
        var param = new FormData();
        param.append('organ_id', organId);
        param.append('staff_type', staffType);
        param.append('staff_id', staffId);
        param.append('user_id', user_id);
        param.append('menu_ids', selectedMenus.join(','));

        loadData(apiGetEnableOrderHours, param, callbackGetReservesDays);


    }, [selectedMenus, staffType, staffId]);
    
    useEffect(()=>{
        setSelectHour('');
        setSelectMin('');
    }, [seletedDate]);

    useEffect(()=>{
        setSelectMin('');
    }, [selectHour]);

    function callbackGetReservesDays(result){
        setShowSpinner(false);
        setReservePosts(result);
    }
    
    const options = {
        message: '予約しますか？',
        buttons: [
          {
            label: '予約する',
            onClick: () => reserve()
          },
          {
            label: 'いいえ',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypress: () => {},
        onKeypressEscape: () => {},
        overlayClassName: "overlay-custom-class-name"
    };

    function reserve() {
        
        setShowSpinner(true);
        if (user_id == 0 ) navigate('/');
        let param = new FormData();
        console.log(user_id);
        param.append('user_id', user_id);
        param.append('organ_id', organId);
        param.append('staff_type', staffType);
        param.append('staff_id', staffId);
        param.append('menu_ids', selectedMenus.join(','));
        param.append('from_time', seletedDate + " " + selectHour + ":" + selectMin + ":00")
        param.append('is_reserve', 1);

        reserveOrder(apiReserveOrder, param, callbackReserve);
    }

    function callbackReserve(result){
        setShowSpinner(false);
        if (result.is_result){
            setCompleteOrder(result.data);
        }else{
            setServerErr(result.err_message);
            setShow(true);
            setSeleteMenu([]);
        }
    }

    const [show, setShow] = useState(false);
    const [err, setServerErr] = useState('');

    const handleClose = () => setShow(false);

    return (
            <Container className="FormLayout">
                <Row className='InputGroupLayout'>
                    <Col>
                        <div className='InputGroupTitle'>
                            店舗
                        </div>
                        <div className="InputGroupBody">
                            <div className='commentLayout'>
                                <span>▼</span>こちらからお選びください。
                            </div>
                            <div className='commentLayout'>
                                <Form.Select onChange={handlerOrganId}>
                                    <option value="">ーー店舗を選択してください。ーー</option>
                                    { organs.map((e)=> <option key={e.organ_id} value={e.organ_id} >{e.organ_name}</option>)}
                                </Form.Select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='InputGroupLayout'>
                    <div className='InputGroupTitle'>
                        スタッフ
                    </div>
                    <div className="InputGroupBody">
                        <Col>
                            <Form.Group>
                                <StaffTypeItem 
                                    label="なし"
                                    name="staff_type"
                                    value={0}
                                    id="staff_type_0"
                                    clickFunc={handlerStaffType}
                                />
                                <StaffTypeItem 
                                    label="男性希望"
                                    name="staff_type"
                                    value={1}
                                    id="staff_type_1"
                                    clickFunc={handlerStaffType}
                                />
                                <StaffTypeItem 
                                    label="女性希望"
                                    name="staff_type"
                                    value={2}
                                    id="staff_type_2"
                                    clickFunc={handlerStaffType}
                                />
                                <StaffTypeItem 
                                    label="あり"
                                    name="staff_type"
                                    value={3}
                                    id="staff_type_3"
                                    clickFunc={handlerStaffType}
                                />
                            </Form.Group>
                            <Form.Select style={{ display : staffType==3 ? 'block' : 'none', marginTop: '12px'}} value={staffId} onChange={handlerStaffId}>
                                <option value="">ーースタッフを選択してください。ーー</option>
                                { staffs.map((e)=> <option key={e.staff_id} value={e.staff_id} >{e.staff_first_name} {e.staff_last_name}</option>)}
                            </Form.Select>
                        </Col>
                    </div>
                </Row>
                <Row>
                    <Col>
                        <div className="CategoryGroup">
                            {categories.map((e) =>
                                <Category key={e.id} cat={e} categoryId={categoryId} clickFunc = {handlerSelectCategory}></Category>
                            )}
                        </div>
                        <div className='GroupBody'>
                            <div className="MenuGroup">
                                {menus.filter(e=> categoryId==0? true : e.category_id == categoryId).map((e)=>
                                    <Menu key={e.menu_id} mu={e} border={selectedMenus.includes(e.menu_id)} clickFunc={handlerSelectMenu}></Menu>
                                )}
                            </div>
                            <Row>
                                <Col>
                                    <Calendar 
                                        tileDisabled={ ({ date }) => !reserves.dates.includes(dateFormat(date)) } 
                                        onChange={handlerSelectDate} value={seletedDate} 
                                        locale="ja"
                                        formatDay = { (locale, date) => dateFormatDay(date) }
                                        
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className = "timeButtonGroup">
                                    {reserves.hours.filter(e => e.date==seletedDate && e.type<3).map((e) =>
                                        <HourBtn key={e.hour} data={e} clickFunc={setSelectHour} selectValue={selectHour}/>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col className = "timeButtonGroup">
                                    {reserves.mins.filter(e => e.type !=3 && e.hour == selectHour && e.date==seletedDate).map((e) =>
                                        <MinuteBtn key={e.min} data={e} seletedValue={selectMin} clickFunc={setSelectMin} />
                                    )}
                                </Col>
                            </Row>
                            <div className="order-bottom-button">
                                <Button type="submit" className="btn-reserve" disabled={!isEnableReserve} onClick={()=>confirmAlert(options)}>予約する</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Body>{err}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    </Modal.Footer>
                </Modal>
                <LoadMask showable={showSpinner} />
            </Container>
            
    )
}

export default Order