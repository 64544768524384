import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap"
import { Form } from "react-bootstrap"
import { useNavigate } from 'react-router-dom';
import logo from '../logo.png';
// import { TimelineItem } from "../components/timeline-item";


function Top() {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    setValidated(true);
    navigate('/order');
  };
  return (
    <Container className="TopLayOut">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col className="logoLayout">
            <img src={logo} alt="logo" />
          </Col>
        </Row>
      </Form>
    </Container>
  )
}


export default Top
