import axios from "axios";

export function loadData (api, param, loadFunc) {
    axios.post(api, param).then(function(response){
            let result = response.data;
            if (result.is_result){
                loadFunc(result.data);
            }
        }).catch((err) => {
            console.log(err.message);
        });
}