import { Form } from "react-bootstrap"
export const StaffTypeItem = ({label, name, value, id, groupValue, clickFunc}) => {
    return (
        <Form.Check
            inline
            label={label}
            name={name}
            type="radio"
            value={value}
            id={id}
            defaultChecked = {groupValue==value}
            onClick = {()=>clickFunc(value)}
        />
    )
}