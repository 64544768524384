
import 'react-calendar/dist/Calendar.css';
import { Routes, Route } from 'react-router-dom';
import {useDispatch} from 'react-redux'
import Top from './pages/top';
import Login from './pages/login';
import Register from './pages/register';
import Order from './pages/order';
import Complete from './pages/complete';
import {authAction} from './app/features/auth'
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css'
import './css/button.css'
import './css/top.css'
import './css/login.css'
import './css/order.css'
import './css/calandar.css'
import React, { useEffect } from 'react';

export const UserId = React.createContext(null);

function App () {
  const dispatch = useDispatch();
  useEffect(() => {
    const user_id = localStorage.getItem('user_id');
    dispatch(authAction(user_id))
  },[])

    return (
      <div>
        <Top />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/order" element={<Order />} />
          <Route path="/complete" element={<Complete />} />
        </Routes>
      </div>
    )
}

export default App