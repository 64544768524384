import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Container, Form, Row,  Button, Alert } from "react-bootstrap"

import { apiLoginUser, companyId } from "../config/const";
import { loginUser } from "../https/login-user";
import { authAction } from "../app/features/auth";
import { FormInput } from "../components/from-input";
import { LoadMask } from "../components/load-mask";


function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);
  const [validated, setValidated] = useState(false);
  
  const [email, setEmail] = useState({ value: "", invalid: false });
  const [password, setPassword] = useState({ value: "", invalid: false });
  const [serverError, setServerError] = useState('');

  const handleEmail = (e) => {
    let value = e.target.value
    let emailReg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    setEmail({ value: value, invalid: !emailReg.test(value) })
  }

  const handlePassword = (e) => {
    let value = e.target.value
    setPassword({ value: value, invalid: value == '' })
  }

  const handlerClickRegister = () => {
    navigate('/register');
  };

  const handlerSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        setValidated(true);
        return;
    }
    setShowSpinner(true);

    let param = new FormData();
    param.append('company_id', companyId);
    param.append('email', email.value);
    param.append('password', password.value);
    loginUser(apiLoginUser, param, callbackLogin);
  };

  function callbackLogin(result){
    setShowSpinner(false);
    if (result.is_result){
      localStorage.setItem('user_id', result.user_id)
      dispatch(authAction(result.user_id))
      navigate('/order');
    }else{
        setServerError(result.err_message);
    }
  }

  return (
    <Container className="FormLayout LoginForm">
      <Form noValidate validated={validated} onSubmit={handlerSubmit}>
        <Row>
            <Alert show={serverError!=''} key="register_error" variant="danger">
                {serverError}
            </Alert>
        </Row>
        <Row>
          <FormInput 
              id="user_email" 
              label="メールアドレス" 
              type="email" 
              handler={handleEmail} 
              value={email} 
              errMsg="メールアドレスを入力してください" 
            />
        </Row>
        <Row>
          <FormInput 
              id="user_password" 
              label="パスワード" 
              type="password" 
              handler={handlePassword} 
              value={password} 
              errMsg="パスワードを入力してください" 
            />
        </Row>
        <div className="loginButton">
            <Button type="submit" className="btn-primary">ログイン</Button>
        </div>
        <div className="loginButton">
            <Button type="button" className="btn-warning" onClick={handlerClickRegister}>新規会員はこちら</Button>
        </div>
      </Form>
      <LoadMask showable={showSpinner} />
    </Container>
  )
}

export default Login