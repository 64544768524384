import React, { useEffect } from 'react';
import { Container, Button } from "react-bootstrap"
import { Row, Col } from "react-bootstrap"
import { Menu } from "../components/menu"
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Modal from 'react-bootstrap/Modal';
function Complete(){

    const navigate = useNavigate();

    const {state} = useLocation();

    useEffect (() => {
        if (!state) navigate('/');
    }, []);

    function renderStaffType(param) {
        switch (param){
            case 1:
                return "男性希望";
            case 2:
                return "女性希望";
            case 3:
                return "指名";
            default:
        }
        return "指名なし";
    }

    function renderOrderMenus(param){
        return param.map((e)=><Menu key={e.id} disabled={true} mu={e} />);
    }

    return (
        <Container className="FormLayout completeForm">
            <h4>ご予約いただきありがとうございます</h4>
            <h5>次の内容で<span style={{fontWeight:'bold'}} className={state.order.status==4 ? "apply" : "request"}>「{state.order.status==4 ? "予約" : "予約リクエスト"}」</span>されました。</h5>
            
            <Row className='InputGroupLayout'>
                    <Col>
                        <div className='InputGroupTitle'>
                            予約内容
                        </div>

                        <div className="InputGroupBody">
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> 店舗名</div>
                                <div className='completeContent'>{state.order.organ_name}</div>
                            </div>
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> こたわり</div>
                                <div className='completeContent'>{ renderStaffType (state.order.select_staff_type) }</div>
                            </div>
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> スタッフ名</div>
                                <div className='completeContent'>{ state.order.staff_first_name } {state.order.staff_last_name}</div>
                            </div>
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> 予約時間</div>
                                <div className='completeContent'>{ state.order.from_time }</div>
                            </div>
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> メニュー</div>
                                <div className='completeContent'>{ renderOrderMenus(state.orderMenus) }</div>
                            </div>
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> 所要時間</div>
                                <div className='completeContent'> { state.order.menu_time }分</div>
                            </div>
                            <div className='completeRow'>
                                <div className='completeRabel'><span>▶</span> インターバル</div>
                                <div className='completeContent'> { state.order.interval }分</div>
                            </div>
                            <div className='order-bottom-button'>
                                <Button type="button" className="btn-reserve" onClick={()=>navigate('/order')}>予約画面に行く</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
        </Container>
    );
}

export default Complete