import { useState } from "react"
import { Button } from "react-bootstrap"
export const Menu = ({mu, border, clickFunc, disabled}) => {
    // const [isBorder, setBorder] = useState(border);

    // const handlerClick = (v) => {
    //     setBorder(v);
    //     clickFunc(v, mu.menu_id);
    // }
    return (
        <Button 
            disabled={disabled}
            style={{backgroundColor:mu.color,color:mu.fcolor, border: border? 'solid red 2px': ''}} 
            onClick={() => clickFunc(mu.menu_id)}>
            {mu.menu_title}
        </Button>
    )
}