import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { Container, Form, Row, Col, Button, Alert } from "react-bootstrap"

import { apiRegisterUser, companyId } from "../config/const";
import { authAction } from "../app/features/auth";
import { registerUser } from "../https/register-user";
import { FormInput } from "../components/from-input";
import { LoadMask } from "../components/load-mask";

function Register() {

  const dispatch=useDispatch()
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [fristName, setFirstName] = useState({ value: "", invalid: false })
  const [lastName, setLastName] = useState({ value: "", invalid: false })
  const [email, setEmail] = useState({ value: "", invalid: false })
  const [password, setPassword] = useState({ value: "", invalid: false })
  const [passwordConf, setPasswordConf] = useState({ value: "", invalid: false })
  const [sex, setSex] = useState({ value: "1", invalid: false })
  const [serverError, setServerError] = useState('');

  const handleFirstName = (e) => {
    let value = e.target.value
    setFirstName({ value: value, invalid: value === '' })
  }

  const handleLastName = (e) => {
    let value = e.target.value
    setLastName({ value: value, invalid: value === ''  })
  }

  const handleEmail = (e) => {
    let value = e.target.value
    let emailReg = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    setEmail({ value: value, invalid: !emailReg.test(value) })
  }

  const handlePassword = (e) => {
    let value = e.target.value
    setPassword({ value: value, invalid: value.length < 8 })
  }

  const handlePasswordConf = (e) => {
    let value = e.target.value
    setPasswordConf({ value: value, invalid: value !== password.value })
  }

  function handleBack () {
    navigate('/');
  }

  function handleSubmit (event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    if (passwordConf.invalid) return;

    setShowSpinner(true);
    let param = new FormData();
    param.append('company_id', companyId);
    param.append('first_name', fristName.value);
    param.append('last_name', lastName.value);
    param.append('email', email.value);
    param.append('password', password.value);
    param.append('sex', sex.value);

    registerUser(apiRegisterUser, param, registerCallBack);

  };

  function registerCallBack(result){
    setShowSpinner(false);
    if (result.is_result){
      dispatch(authAction({type:'user_id',data:result.user_id}))
      navigate('/order');
    }else{
      setServerError(result.err_message);
    }
  }

  return (
    <Container className="FormLayout">
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
          <Row>
            <Alert show={serverError!==''} key="register_error" variant="danger">
              {serverError}
            </Alert>
          </Row>
          <Row>
          <FormInput 
              id="user_first_name" 
              label="姓" 
              type="text" 
              handler={handleFirstName} 
              value={fristName} 
              errMsg="この項目は必須です。入力してください" 
            />
          <FormInput 
              id="user_last_name" 
              label="名" 
              type="text" 
              handler={handleLastName} 
              value={lastName} 
              errMsg="この項目は必須です。入力してください" 
            />
        </Row>
        <Row>
          <FormInput 
              id="user_email" 
              label="メールアドレス" 
              type="email" 
              handler={handleEmail} 
              value={email} 
              errMsg="有効なEメールアドレスを入力してください" 
            />
        </Row>
        <Row>
          <FormInput 
              id="user_password" 
              label="パスワード" 
              type="password" 
              handler={handlePassword} 
              minLength={8}
              value={password} 
              errMsg="8文字以上で入力してください" 
            />
        </Row>
        <Row>
          <FormInput 
              id="user_password_conf" 
              label="パスワード確認" 
              type="password" 
              handler={handlePasswordConf} 
              value={passwordConf} 
              errMsg="パスワードが正しくありません" 
            />
        </Row>
        <Row>
          <Col>
              <Form.Label>性別</Form.Label>
              <div>
                <Form.Check
                  inline
                  label="男性"
                  name="user_radio"
                  type="radio"
                  id="user_sex_1"
                  value="1"
                  defaultChecked="true"
                  onClick={()=>setSex({value:1, invalid: false})}
                />
                <Form.Check
                  inline
                  label="女性"
                  name="user_radio"
                  type="radio"
                  id="user_sex_2"
                  value="2"
                  onClick={()=>setSex({value:2, invalid: false})}
                />
              </div>
          </Col>
        </Row>
        <Row className="bottomButtonGroup">
          <Col>
              <Button type="button" className="btn-grey" onClick={handleBack} >{'< '} 戻る</Button>
              <Button type="submit" className="btn-primary" >登録する</Button>
          </Col>
        </Row>
      </Form>
      <LoadMask showable={showSpinner}></LoadMask>
    </Container>
  )
}

export default Register