export const dateFormat = (d) => {
    let dt = new Date(d);
    let year = dt.getFullYear();
    let month = dt.getMonth() + 1;
    let day = dt.getDate();

    return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
}

export const dateFormatDay = (d) => {
    let dt = new Date(d);
    return dt.getDate();
}