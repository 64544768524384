import axios from "axios";

export function reserveOrder (api, param, callbackFunc) {
    axios.post(api, param).
        then(function(response){
            let result = response.data;
            callbackFunc(result);
        }).catch((err) => {
            console.log(err.message);
        });
}