import { Button } from "react-bootstrap"
export const Category = ({cat, categoryId, clickFunc}) => {
    const handleClick = (id) => {
       clickFunc(id);
    }
    return (
        <Button 
            style={{backgroundColor:cat.color, borderBottom : categoryId==cat.id ? 'none' : ''}}
            onClick={()=>handleClick(cat.id)}>{cat.alias}<div>▼</div></Button>
    )
}