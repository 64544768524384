import { Col, Form } from "react-bootstrap"
export const FormInput = ({id, label, type, handler, value, errMsg, minLength}) => {
    return (
        <Col>
            <Form.Group controlId={id}>
              <Form.Label>{label}</Form.Label>
              <Form.Control
                        minLength={minLength}
                        required
                        type={type}
                        onChange={handler}
                        isInvalid={value.invalid}
                        onBlur={handler}
                  />
              <Form.Control.Feedback type="invalid" >{errMsg}</Form.Control.Feedback>
            </Form.Group>
        </Col>
    )
}