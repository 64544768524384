import { createSlice } from "@reduxjs/toolkit";

const init={
    user_id:0
}

export const authSlice=createSlice({
    name:"authSlice",
    initialState:init,
    reducers:{
        authAction:(state,{payload})=>{
            state.user_id = payload;
        }
    }
})

export const {authAction}=authSlice.actions

export default authSlice.reducer