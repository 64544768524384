const apiBaseUrl = 'https://silverfeathers.net/apis/';

export const companyId = 2;

export const apiRegisterUser = apiBaseUrl + "user/registerUser";
export const apiLoginUser = apiBaseUrl + "user/loginUser";

export const apiGetOrgans = apiBaseUrl + "organs/getOrganList";

export const apiGetStaffs = apiBaseUrl + "staff/getStaffList";

export const apiGetCategories = apiBaseUrl + "menu/getCategoryList";
export const apiGetMenus = apiBaseUrl + "menu/getMenuList";

export const apiGetEnableOrderDays = apiBaseUrl + "order/getOrderEnableDays";
export const apiGetEnableOrderHours = apiBaseUrl + "order/getOrderEnableHours";

export const apiReserveOrder = apiBaseUrl + "order/reserveOrder";