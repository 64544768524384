
import { Spinner } from "react-bootstrap"
export const LoadMask = ({showable}) => {
    return (
        <div className={showable ? 'loadMask' : 'visually-hidden loadMask'} variant="dark" >
            <Spinner animation="border" role="status"  >
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}